<template>
    <modal-layout
        v-if="dialog"
        :name="name"
        :hidden="hidden"
        size="medium"
        :style="{ 'z-index': order }"
        @closeModal="hideModal(name)"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ title }}</h2>
        </template>
        <template #content>
            <div :class="{ 'circle-image' : circleMode }">
                <slot name="content" />
            </div>
        </template>
        <template #buttons>
            <custom-button
                default
                @on-btn-click="confirmModal(name)"
            >
                Confirm
            </custom-button>
            <custom-button
                default
                @on-btn-click="hideModal(name)"
            >
                Cancel
            </custom-button>
            <custom-button
                default
                type="button"
                width="40px"
                classes="bg-none"
                @on-btn-click="rotate(90)"
            >
                <rotate-right />
            </custom-button>
            <custom-button
                default
                type="button"
                width="40px"
                classes="bg-none"
                @on-btn-click="rotate(-90)"
            >
                <rotate-left />
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'
import RotateLeft from 'mdi-vue/RotateLeft'
import RotateRight from 'mdi-vue/RotateRight'

export default {
    name: 'CroperModal',
    components: { RotateLeft, RotateRight },
    mixins: [
        modalsEventBusMixins
    ],
    props: {
        title: String,
        content: String,
        name: String,
        circleMode: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        rotate(deg) {
            this.$emit('rotate', deg)
        }
    }
}
</script>

<style>
.avatar-cropper {
    max-height: 60vh;
}

.avatar-cropper .cropper-view-box {
    height: 100%;
    outline: 1px solid #39f;
    outline-color: rgba(51,153,255,.75);
    overflow: hidden;
    width: 100%;
}

.circle-image .avatar-cropper .cropper-view-box {
    border-radius: 50%!important;
}

.cropper-wrap-box {
    background: #808080;
}
</style>
